<template>
  <div>
    <v-page-title title="Knowledge Base">
      <v-container>
        <v-row align="center">
          <v-col cols="2">

          </v-col>
          <v-col cols="2">

          </v-col>
          <v-col cols="6">
          </v-col>
          <v-col cols="2" align="end">
            <v-btn
              color="white"
              small
              @click="dialogInsert = true"
            >
              <v-icon left small>mdi-plus</v-icon>
              New Post
            </v-btn>            
          </v-col>
        </v-row>
      </v-container>
    </v-page-title>

    <v-card max-width="1200px" class="mx-auto my-5" elevation="0" color="transparent">
      <v-container>
        <v-row dense>
          <v-col>
            <v-loader v-if="!posts"></v-loader>
            <div class="hounddog-table" v-else>
              <v-data-table
                item-key="id"
                :headers="headers" 
                :items="posts" 
                :items-per-page=1000000
                no-data-text="No posts yet."
                hide-default-footer
              >

                <template v-slot:item.title="{ item }">
                  <b>{{ item.title }}</b><br>
                </template>

                <template v-slot:item.created_at="{item }">
                  {{ $date.fullDateTime(item.created_at, 30) }}
                </template>

                <template v-slot:item.user_name="{item }">
                  <b>{{ item.name }}</b><br>
                </template>

                <template v-slot:item.responses="{item }">
                  <b>{{ item.total_responses }}</b><br>
                </template>

                <template v-slot:item.view="{item}">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" dark @click="viewPost(item.id)" color="blue-grey darken-4" fab small elevation="6">
                        <v-icon color="#fff" small>mdi-open-in-app</v-icon>
                      </v-btn>
                    </template>
                    <span>View Post</span>
                  </v-tooltip>
                </template>

              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

  </div>
</template>

<script>
  export default {
    name: 'KnowledgeBase.vue',
    data() {
      return {
        showOnly:'all',
        showingOnlyFilter: [
          {id: 'all', label: 'All'},
          {id: 'active', label: 'All Active'},
          {id: 'deactivated', label: 'All Deactivated'}
        ],
        selectedIndex: 0,
        dialogInsert: false,
        dialogDelete: false,
        dialogUpdate: false,
      }
    },
    created(){

    },
    computed:{
      headers(){
        let ret = [
          { text: "Title", value: "title", divider: true},
          { text: "Date", value: "created_at", width: '250px', align:'center', divider: true},
          { text: "Author", value: "user_name", width: '250px', align:'center', divider: true},
          { text: "Responses", value: 'responses', width: '155px', align:'center', divider: true },
        ];
        return ret;
      },
      posts(){
        return this.$store.getters['knowledgebase/all'];
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>